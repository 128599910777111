<template>
  <!-- 展示上报指令问题，添加if -->
  <div v-if="matDetail.id" v-reportView:goodsdetail="{ actiontype: 1, goodsid: matDetail.id, goodstype: level1Index, free: matDetail.pay_type ? 2 : 1, classid: matDetail.class_id, showsource: '', clickid: '', ext: '' }" class="detail_box">
    <div class="detail_header">
      <div class="title_box">
        <div class="callback" @click="goBack"><IconArrow /><span class="font_word">返回 </span></div>
        <div class="title_content">
          <div v-show="matDetail.pay_type === 0" class="pay_lable">{{ matDetail.pay_type === 0 ? '免费' : '付费' }}</div>
          <p class="header_title ellipsis_one">{{ matDetail.name }}</p>
        </div>
      </div>
      <div class="top_right">
        <span>
          <ShareButton v-if="collectActive" class="collect_btn" @click="changeCollect">已收藏</ShareButton>
          <ShareButton
            v-else class="collect_btn need_collect"
            @click="changeCollect">
            <span><span class="icon_collect" /><span class="font">收藏</span></span>
          </ShareButton>
        </span>
        <ShareButton class="down_btn" :class="btnTheme().type" @click="btnHandle()">
          <span><span class="icon" /><span class="font">{{ btnTheme().font }}</span></span>
        </ShareButton>
      </div>
    </div>

    <vueScroll class="model_detail">
      <img
        v-if="curImgStatus === imgStatus.loading" src="~@/assets/images/bg_loading.png" :alt="matDetail.name" lazy="loading">
      <img v-else-if="curImgStatus === imgStatus.loaded" :src="detailImg" :alt="matDetail.name">
      <img
        v-else src="~@/assets/images/bg_loaded.png" :alt="matDetail.name" lazy="error">
    </vueScroll>

    <span
      v-show="hasLeft" class="icon_arrow left"
      @click="arrowHandle(left)" />
    <span
      v-show="hasRight" class="icon_arrow right"
      @click="arrowHandle(right)" />
  </div>
</template>

<script>
import IconArrow from '@/components/Icons/IconArrow.vue'
import ShareButton from '@shared/components/Button/Button.vue'

import api from '@/api/index.js'
import { modelStatus, MatId, pageSize, Page, Scroll, modelItemHeight } from '@/common/const.js'
import { detailImgRule } from '@/common/resources.js'
import { updatePrivliege } from '@/common/business.js'
import { RetCode, limitCode, limitCodeByPay } from '@/api/bussinessCode.js'
import { ClientDownFile, ClientOpenFile } from '@/common/clientFunc.js'
import { parseURL } from '@/common/utils.js'
import { report } from '@/report/index.js'
import { getSource } from '@/common/cache.js'

const needSwitchArr = ['classify', 'search']

const lineNum = 4 // 行列表的数量
const lastScroll = modelItemHeight * 5 // 到底的scrol

const imgStatus = {
  loading: 'loading',
  error: 'error',
  loaded: 'loaded'
}

const scrollH = (active) => { // scroll高于不用管，scroll组件支持
  return Math.floor(active / lineNum) * modelItemHeight
}

export default {
  name: 'Detail',

  components: { IconArrow, ShareButton },

  data () {
    return {
      left: 'left',
      right: 'right',
      modelStatus,
      matDetail: {},
      detailImg: null,
      imgStatus,
      curImgStatus: imgStatus.loading
    }
  },

  computed: {
    userInfo () {
      return this.$store.state.userInfo
    },

    downedList () {
      return this.$store.state.downedList
    },

    downingList () {
      return this.$store.state.downingList
    },

    downType () {
      const matId = this.matDetail.id || ''
      const id = matId + ''

      let type = this.downedList.includes(id) ? modelStatus.downed : modelStatus.down
      type = this.downingList.includes(id) ? modelStatus.downing : type

      return type
    },

    isLoginedIn () {
      return this.$store.getters.isLoginedIn
    },

    level1Index () {
      return this.$store.state.categoryActive
    },

    level1List () {
      return this.$store.state.operational.categoryArr
    },

    matList () {
      return this.$store.state.matList
    },

    matTotalCount () {
      return this.$store.state.matTotalCount
    },

    commonParams () {
      return this.$store.state.commonParams
    },

    orderItem () { // 定位当前id在数据的什么位置， 返回该对象
      const query = this.$route.query

      return this.matList.find((item, idx) => {
        item.index = idx
        return String(item.id) === query[MatId]
      })
    },

    collectIdList () {
      return this.$store.state.collectIdList
    },

    collectActive () {
      return this.collectIdList.includes(this.matDetail.id)
    },

    hasRight () {
      if (!this.needSwitch() || !this.orderItem) { return false }

      if (this.matTotalCount < pageSize) { // 小于一页的情况
        return (this.orderItem.index + 1) < this.matTotalCount
      }

      return ((this.commonParams[Page] - 1) * pageSize + (this.orderItem.index + 1)) < this.matTotalCount
    },

    hasLeft () { // 左侧条件满足不了，只能在调用列表接口的时候，存储接口参数
      if (!this.needSwitch() || !this.orderItem) { return false }
      return !(this.commonParams[Page] === 1 && this.orderItem.index === 0)
    },
  },

  mounted () {
    this.getMatDetail()
  },

  methods: {
    needSwitch () {
      const query = this.$route.query

      return needSwitchArr.includes(query.from)
    },
    /**
     * 左右切换的主功能函数，较为复杂
     */
    // eslint-disable-next-line complexity
    async arrowHandle (flagStr) { // left是左边按钮，right是右边
      // 节流: 1、图片加载完成节流；2、至少500毫秒节流
      if (this.timer || this.timer1) { return }
      this.timer = true
      this.timer1 = setTimeout(() => { this.timer1 = null }, 500)

      this.curImgStatus = imgStatus.loading

      const query = this.$route.query
      const item = this.orderItem
      const left = flagStr === this.left
      const nextActive = item.index + 1

      left ? this.reportFunc(3) : this.reportFunc(4)

      if ((left && item.index === 0) || (!left && nextActive === this.matList.length)) {
        await this.$store.dispatch(`getMatBy${query.from}`, left ? -1 : 1)
        // 保障数据一致性，知道来源，详情页执行翻页的时候知道调用哪个接口，上面一行代码。。
        this.$router.push(`/detail?from=${query.from}&${MatId}=${this.matList[left ? (this.matList.length - 1) : 0].id}`)
        this.getMatDetail()
        this.$store.commit('changeCacheParams', { from: query.from, params: { [Scroll]: left ? lastScroll : 0 } })
        return
      }

      this.$router.push(`/detail?from=${query.from}&${MatId}=${this.matList[left ? (item.index - 1) : nextActive].id}`) // 保障统一性
      this.getMatDetail()
      this.$store.commit('changeCacheParams', { from: query.from, params: { [Scroll]: scrollH(left ? (item.index - 1) : nextActive) } })
    },

    btnTheme () {
      const modelMap = {
        [modelStatus.down]: { type: 'down', font: '立即下载' },
        [modelStatus.downing]: { type: 'downing', font: '正在下载' },
        [modelStatus.downed]: { type: 'downed', font: '打开' }
      }

      return modelMap[this.downType]
    },

    getMatDetail () {
      const query = this.$route.query

      const params = { data: { [MatId]: Number(query[MatId]) } }

      api.material.matDetail(params).then(res => {
        this.matDetail = res?.data?.info || {}

        // 手工加载图片，v-lazy存在图片还没加载完成，样式已经更改的情况
        this.loadImage()
      })
    },

    loadImage () {
      const imgUrl = this.matDetail.big_img + detailImgRule
      const img = new Image()

      img.src = imgUrl

      img.onload = () => {
        this.curImgStatus = imgStatus.loaded
        this.detailImg = imgUrl
        this.timer = false
      }
      img.onerror = () => {
        this.curImgStatus = imgStatus.error
        this.reportFunc('', 1, 1)
      }
    },

    goBack () {
      this.reportFunc(2)

      this.$router.push(`/${this.$route.query.from}?from=detail`)
    },

    reportFunc (clickid, type = 2, ext = '') {
      report('goodsdetail', { actiontype: type, goodsid: this.matDetail.id, goodstype: this.level1Index, free: this.matDetail.pay_type ? 2 : 1, classid: this.matDetail.class_id, showsource: '', clickid, ext })
    },

    btnHandle () {
      if (this.downType === modelStatus.downing) { return }

      if (!this.isLoginedIn) {
        this.$store.commit('changeLoginDialogVisible', true)
        return
      }

      if (this.downType === modelStatus.down) {
        updatePrivliege(this.$store).finally(() => {
          this.download()
          this.reportFunc(0)
        })
        return
      }

      this.reportFunc(1)
      ClientOpenFile({ id: this.matDetail.id + '' }, (res) => {
        if (!res?.result) {
          this.$toast({ desc: '打开文件失败，请联系客服' })
        }
      })
    },

    cutSuffix (url) {
      const lastUrl = parseURL(url).file
      return lastUrl.slice(lastUrl.indexOf('.'))
    },

    reportDownload (item, ext = '') {
      report('download', { goodsid: item.id, goodstype: this.level1Index, free: '', classid: this.getClassid, ext })
    },

    download () {
      const id = this.matDetail.id + ''

      api.material.download({ data: { [MatId]: this.matDetail.id, source: getSource() } }).then(res => {
        const url = res?.data?.url
        if (url) {
          this.$store.commit('pushDowningList', id)

          this.reportDownload(this.matDetail)
          ClientDownFile({ id, filename: this.matDetail.name + this.cutSuffix(url), url })
          return
        }
        this.$toast({ desc: '获取链接失败，请联系客服' })
      }).catch(data => {
        const ret = data?.ret || ''
        const code = ret.toString()

        if (code === limitCodeByPay) {
          this.reportDownload(this.matDetail, '1')
          this.$store.commit('changeBuyDialogVisible', true)
          return
        }

        if (code === limitCode) {
          this.reportDownload(this.matDetail, '2')
          this.$store.commit('tipsDialog/changeUpdateVipLevelDialog')
          return
        }

        this.reportDownload(this.matDetail, '3')

        if (code in RetCode) {
          this.$toast({ desc: RetCode[data.ret] })
        }
      })
    },

    changeCollect () {
      if (this.collectActive) { // 该字段外部协定的，非接口定义
        this.reportFunc(6)
        this.$store.dispatch('removeCollectId', this.matDetail.id)
        return
      }
      this.reportFunc(5)
      this.$store.dispatch('addCollectId', this.matDetail.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.detail_box { position: absolute; top: 0; right: 0; bottom: 0; left: 70px; display: flex; flex-direction: column;
  .detail_header { display: flex; align-items: center; justify-content: space-between; height: 53px; padding: 0 16px; background-color: #fff; border-bottom: 1px solid #dedede; }
  .title_box { display: flex; flex: 1; margin-right: 50px;
    .callback { margin-right: 50px; font-size: 14px;; line-height: 14px; cursor: pointer;
      .font_word { margin-left: 8px; font-weight: 400; color: #666; vertical-align: middle; }
    }

    .title_content { display: flex; flex: 1; justify-content: center;
      .pay_lable { padding: 0 5px; margin-right: 10px; font-size: 14px; color: #fff; background-color: $primary_color; border-radius: 4px; }

      .header_title { display: flex; align-items: center; font-size: 16px; font-weight: 400; color: #333; }
    }
  }

  .top_right { display: flex; align-items: center; height: 100%;
    .collect_btn { width: 78px; height: 32px; padding: 0 14px; margin-right: 10px; font-size: 14px; color: $primary_yellow; cursor: pointer; background-color: #fff; border: 1px solid $primary_yellow; border-radius: 4px; outline: none;
      &.need_collect { color: #fff; background-color: $primary_yellow;
        &:hover { background-color: #FFC05D; }
        .icon_collect { display: inline-block; width: 14px; height: 14px; margin-right: 6px; vertical-align: middle; background-image: url(~@/assets/images/icon_collect_detail.png); background-size: cover; }
        .font { font-weight: 400; vertical-align: middle; }
      }
    }

    .down_btn { height: 32px; padding: 0 14px; color: #fff; cursor: pointer; border: none; border-radius: 4px; outline: none;
      .icon { display: inline-block; width: 14px; height: 14px; margin-right: 6px; vertical-align: middle; background-size: cover; }
      &.down { background-color: $primary_color;
        &:hover { background-color: #68B1FF; }
        .icon {  background-image: url(~@/assets/images/icon_down.png); }
      }
      &.downing { background-color: $primary_color;
        .icon { background-image: url(~@/assets/images/icon_downing.gif); }
      }
      &.downed { color: $primary_color; background-color: #fff; border: 1px solid $primary_color;
        .icon { background-image: url(~@/assets/images/icon_down_active.png); }
      }

      .font { font-size: 14px; font-weight: 400; vertical-align: middle; }
    }
  }

  .model_detail { position: relative; flex: 1; overflow: auto; text-align: center;
    img { box-sizing: border-box; max-width: 100%; padding: 5px 20px 80px 20px; }
    img[lazy=loading], img[lazy=error] { width: 420px; margin-top: 49px; }
  }

  .icon_arrow { position: absolute; top: 50%; display: inline-block; width: 48px; height: 48px; cursor: pointer; background-size: cover;
    &:hover { background-position: center; }
    &:active { background-position: right; }
    &.left { left: 32px;  background-image: url(~@/assets/images/icon_switch_left.png); }
    &.right { right: 42px;  background-image: url(~@/assets/images/icon_switch_right.png); }
  }
}
</style>
